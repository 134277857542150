<template>
  <div class="wrap">
    <div @dblclick="disCopyRight" style="width: 15px; height: 15px; display: inline-block; cursor: pointer; top: 9999; position: absolute; top: 0px; left: 0px;"></div>
    <div v-if="!isFullScreen" class="menu no-print" :class="menuToggle">
      <menu-header ref="menuHeader" :is-collapse="isCollapse"></menu-header>
      <div class="menu-content">
        <nav-menu :is-collapse="isCollapse" ref="navMenu"></nav-menu>
        <div v-if="userUnAuth" @click="handleUnAuthTip" class="un-auth-mask" :style="isCollapse ? 'width: 64px' : 'width:200px'"></div>
      </div>
      <div class="menu-footer" v-if="!isCollapse">
        <div class="version">version: {{ version }}</div>
        <img src="@/assets/logo/left-menu.png" alt="menuFoot" />
      </div>
    </div>
    <div class="main">
      <div v-if="!isFullScreen" class="header no-print">
        <div class="header-left" @click="collapseMenu">
          <i v-if="!isCollapse" style="cursor: pointer" class="el-icon-s-fold"></i>
          <i v-if="isCollapse" style="cursor: pointer" class="el-icon-s-unfold"></i>
        </div>
        <div class="header-right">
          <global-organization v-show="globalOrg" />
          <!-- <span class="item" @click="handleContactUs">联系我们</span> -->
          <to-do-list v-if="!userUnAuth" ref="child"></to-do-list>
          <div class="usr_info">
            <el-dropdown @command="handleActions">
              <span class="el-dropdown-link">
                {{ nickName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="PersonalCenter">个人中心</el-dropdown-item>
                <el-dropdown-item command="SignOff">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <el-tabs v-if="tabsList.length" closable v-model="curTab" type="border-card" @tab-click="tabClick" @tab-remove="tabRemove" style="height: 38px; margin-bottom: 20px">
        <el-tab-pane v-for="item in tabsList" :key="item.path" :label="item.name" :name="JSON.stringify({ path: item.path, query: item.query })"></el-tab-pane>
      </el-tabs>
      <div class="content">
        <router-view></router-view>
      </div>
      <div class="record" v-html="copyRight"></div>
    </div>
    <el-dialog append-to-body class="dialog" title="联系我们" :visible.sync="contactUsOpen" width="500px">
      <div v-loading="contactUsLoading" style="text-align: initial; padding: 20px 50px">
        <div v-for="(item, idx) in contactPhones" :key="idx">
          <img src="@/assets/icon/service.png" />
          <span>联系电话-{{ idx + 1 }}：{{ item }}</span>
        </div>
      </div>
      <img class="backImg" src="@/assets/icon/contact.png" />
    </el-dialog>
    <el-dialog append-to-body class="dialog" :title="title" :visible.sync="shopService" width="428px">
      <div>
        <span>请联系我公司商务人员，赵经理</span>
      </div>
      <div>
        <img src="@/assets/icon/telephone.png" />
        <span>18511536389</span>
      </div>
      <img class="backImg" src="@/assets/icon/contact.png" />
    </el-dialog>
    <login-expired></login-expired>
    <el-dialog append-to-body class="dialog" :title="unAuthTitle" :visible.sync="unAuthVisible" :center="true" :close-on-click-modal="false" width="60%">
      <menu-explain style="padding: 0px 30px 20px 30px" :content="auAuthDescription"></menu-explain>
    </el-dialog>
  </div>
</template>

<script>
import LoginExpired from '@/components/login/Expired';
import MenuExplain from '@/views/global/MenuExplain.vue';
import NavMenu from '@/components/layout/NavMenu';
import BreadCrumb from '@/components/layout/BreadCrumb';
import ToDoList from '@/components/layout/ToDoList';
import MenuHeader from '@/entry/pdt/component/MenuHeader';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import GlobalOrganization from '@/components/widgets/GlobalOrganization';

import { ReqSignOff } from '@/service/login';
import { getTntConfig } from '@/service/system/config';
import { copyRightMixin, topTabsMixin } from '@/util/mixins';

const packInfo = require('../../../package.json');
export default {
  name: 'Layout',
  mixins: [copyRightMixin, topTabsMixin],
  components: {
    NavMenu,
    BreadCrumb,
    ToDoList,
    LoginExpired,
    MenuExplain,
    MenuHeader,
    OrganizationSelect,
    GlobalOrganization
  },
  data() {
    return {
      centerColData: '',
      colData: '',
      version: packInfo.version,
      hotelColData: '',
      merchandiseInfoData: '',
      customerInfoData: '',
      recognizeFailureLineSet: '',
      originalOrderLineSet: '',
      middleKindLineSet: '',
      otherInvoiceLineSet: '',
      setOrgId: '',
      isFullScreen: false,
      disabled: false,
      title: '',
      hadLogin: true,
      contactUsOpen: false, //联系我们
      contactPhones: ['010-81746652', '400-6080017'],
      contactUsLoading: false,
      shopService: false, //购买服务
      isCollapse: false, //导航栏缩小
      nickName: localStorage.getItem('nickName'),
      unAuthTitle: '票单通功能介绍',
      unAuthVisible: false,
      auAuthDescription: '您所选择模块是收费功能，如需开通请联系多啦客户经理！18511536389'
    };
  },
  created() {
    // console.log(this.$route);
    if (document.body.clientWidth < 1400) {
      this.isCollapse = true;
      this.disabled = true;
    }
  },
  computed: {
    menuToggle() {
      return this.isCollapse ? 'menu_64' : 'menu_auto';
    },
    userUnAuth() {
      return !this.$store.state.user.currUser.enterprise;
    },
    globalOrg() {
      return this.$store.state.sys.show;
    }
  },
  mounted() {
    window.onresize = () => {
      if (document.body.clientWidth < 1400) {
        this.isCollapse = true;
        this.disabled = true;
      } else {
        this.isCollapse = false;
        this.disabled = false;
      }
    };
  },
  methods: {
    handleUnAuthTip() {
      this.unAuthVisible = true;
    },
    handleActions(command) {
      switch (command) {
        case 'SignOff':
          this.doSignOff();
          break;
        case 'PersonalCenter':
          this.personalCenter();
          break;
      }
    },
    handleDeviceState() {
      this.$router.replace('/system/equipment');
    },

    handleContactUs() {
      this.contactUsOpen = true;
      this.contactUsLoading = true;
      getTntConfig('BASE_SYS_CONTACT_PHONE').then((res) => {
        this.contactUsLoading = false;
        if (res.success && res.data) {
          this.contactPhones = res.data.split(/;|；/);
        }
      });
    },
    async doSignOff() {
      await ReqSignOff();
      this.$refs.menuHeader.handleTitle('登录页面', 'favicon.ico');
      this.colData = JSON.parse(localStorage.getItem('lineSet'));
      this.centerColData = JSON.parse(localStorage.getItem('centerLineSet'));
      this.hotelColData = JSON.parse(localStorage.getItem('hotelLineSet'));
      this.merchandiseInfoData = JSON.parse(localStorage.getItem('merchandiseInfo'));
      this.customerInfoData = JSON.parse(localStorage.getItem('customerInfo'));
      this.recognizeFailureLineSet = JSON.parse(localStorage.getItem('recognizeFailureLineSet'));
      this.originalOrderLineSet = JSON.parse(localStorage.getItem('originalOrderLineSet'));
      this.middleKindLineSet = JSON.parse(localStorage.getItem('middleKindLineSet'));
      this.emailInfoData = JSON.parse(localStorage.getItem('emailInfo'));
      this.eventInfoData = JSON.parse(localStorage.getItem('eventInfo'));
      this.otherInvoiceLineSet = JSON.parse(localStorage.getItem('otherInvoiceLineSet'));
      this.setOrgId = JSON.parse(localStorage.getItem('setOrgId'));
      const activeName = localStorage.getItem('activeName');

      localStorage.clear();
      localStorage.setItem('lineSet', JSON.stringify(this.colData));
      localStorage.setItem('centerLineSet', JSON.stringify(this.centerColData));
      localStorage.setItem('hotelLineSet', JSON.stringify(this.hotelColData));
      localStorage.setItem('merchandiseInfo', JSON.stringify(this.merchandiseInfoData));
      localStorage.setItem('customerInfo', JSON.stringify(this.customerInfoData));
      localStorage.setItem('recognizeFailureLineSet', JSON.stringify(this.recognizeFailureLineSet));
      localStorage.setItem('originalOrderLineSet', JSON.stringify(this.originalOrderLineSet));
      localStorage.setItem('middleKindLineSet', JSON.stringify(this.middleKindLineSet));
      localStorage.setItem('otherInvoiceLineSet', JSON.stringify(this.otherInvoiceLineSet));
      localStorage.setItem('emailInfo', JSON.stringify(this.emailInfoData));
      localStorage.setItem('eventInfo', JSON.stringify(this.eventInfoData));
      localStorage.setItem('activeName', activeName);
      localStorage.setItem('setOrgId', JSON.stringify(this.setOrgId));
      this.$router.replace('/login');
    },
    collapseMenu() {
      if (!this.disabled) {
        this.isCollapse = !this.isCollapse;
        this.$store.commit('changeIsCollapse', this.isCollapse);
      } else {
        this.toast('当前分辨率过小。影响使用', 'warning');
      }
    },
    // 个人中心
    personalCenter() {
      this.$router.replace({ path: '/personalCenter' });
    },
    handleOrgChange() {}
  }
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  font-family: 'PingFang SC', 'Hiragino Sans GB', 'Helvetica Neue', 'Microsoft YaHei', Arial, Helvetica, sans-serif;
  overflow: hidden;

  .menu {
    background-color: #327ae3;
    width: auto;
    &.menu_64 {
      width: 64px;
    }
    &.menu_auto {
      width: auto;
    }
    .menu-content {
      overflow-y: auto;
      height: calc(100% - 170px);
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .menu-footer {
      display: flex;
      height: 114px;
      width: 200px;
      justify-content: center;
      align-items: center;

      .version {
        position: fixed;
        bottom: 4px;
        left: 4px;
        color: #ffffff;
      }

      img {
        height: 114px;
        width: 114px;
      }
    }
  }

  .main {
    flex-direction: column;
    flex: 1;
    background-color: #eaeff8;
    overflow: auto;

    .header {
      display: flex;
      height: 56px;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
      padding: 0 24px;

      .header-left {
        font-size: 22px;
        color: #193483;
        height: 30px;
        width: 30px;

        i {
          display: inline-block;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        span {
          margin-left: 16px;
        }
      }

      .header-right {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #666666;

        .item {
          font-size: 14px;
          margin-left: 24px;
          cursor: pointer;
        }

        .usr_info {
          margin-left: 24px;
        }
      }

      .header-right span:hover {
        color: #1890ff;
      }
    }

    .content {
      margin: 0 24px 20px;
    }
  }
}

.dialog {
  div {
    margin-bottom: 16px;
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    vertical-align: sub;
  }

  .backImg {
    width: 100%;
    height: 44px;
    margin: 0;
    vertical-align: bottom;
  }
}

.un-auth-mask {
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  opacity: 0;
}
</style>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
